import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content } from '../../components/home/homeStyledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/Footer';
import ResetPasswordBlock from '../../components/MyAccount/ResetPasswordBlock';
import { trackEvent } from '../../services/analytics';
import callApi from '../../services/api';
import Layout from '../../layouts/index';

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = { status: 'loading' };
    this.sendResetPassword = this.sendResetPassword.bind(this);
  }

  componentDidMount() {
    const token = this.props.location.pathname && this.props.location.pathname.split('/')
      && this.props.location.pathname.split('/')[2];
    this.setState({ token }); // eslint-disable-line
    if (!token) {
      this.setState({ status: 'error' }); // eslint-disable-line
    } else {
      this.checkForgottenPasswordRequest(token);
    }
  }

  checkForgottenPasswordRequest(token) {
    callApi(`checkForgottenPasswordRequest/${token}`).then(() => {
      this.setState({ status: 'ready' });
    }).catch(() => this.setState({ status: 'error' }));
  }


  sendResetPassword() {
    const { token, password } = this.state;
    this.setState({ status: 'loading' });
    callApi('resetPassword', 'post', { token, password })
      .then(() => {
        console.log('success');
        this.setState({ status: 'success' });
      })
      .catch(() => {
        console.log('error');
        trackEvent('erreur', 'echec-envoi-demande-nouveau-mdp');
        this.setState({ status: 'error' });
      });
  }

  render() {
    return (
      <Layout>
        <Container>
          <Content>
            <MenuBar />
            <ResetPasswordBlock
              status={this.state.status}
              inputChange={(field, value) => this.setState({ [field]: value })}
              submit={this.sendResetPassword}
            />
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

ResetPassword.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default ResetPassword;
